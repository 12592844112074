window.$ = $;
window.jQuery = $;
window.jquery = $;

window.bootstrap =  require ('bootstrap')

import App from '6tm-components';
import autosize from 'autosize';

window.App = App;
window.autosize = autosize;

/* Jquery validation */
// import 'jquery-ui-dist/jquery-ui'//ajout

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

/* Globalize */
import Globalize from '6tm-globalize';
import '6tm-globalize/src/cultures/fr-FR';
window.Globalize = Globalize;

/* Import kendo */
import '@progress/kendo-ui/';
import '@progress/kendo-ui/js/kendo.grid'; 

import '@progress/kendo-ui/js/kendo.timezones.js';
import '@progress/kendo-ui/js/kendo.aspnetmvc.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js';

import './kendo-ui-license.js';


//import 'coreui-free-bootstrap-admin-template/src/js/app';

import _buttonbar from './js/_buttonbar';
//import _SizingTvTheme from './js/_SizingTvTheme';
import _navbar from './js/_navbar';
import _ResizeElmnt from './js/_ResizeElmnt';


/* Quick fix for aside toggler with aside tab target */
$('.aside-menu-toggler-with-target').on('click', () => {
    
    var target = $(this).data('target');
    var target_tab = $('.aside-menu a[href="' + target + '"]');
    
    if ($('body').hasClass('aside-menu-hidden')) {
        $('body').removeClass('aside-menu-hidden');
    } else if (target_tab.hasClass('active')) {
        $('body').addClass('aside-menu-hidden');
    }
    
    target_tab.tab('show');
    
    // resizeBroadcast();
});

//Permet de g�rer les dropdown des menus
$(document).on("click", ".nav-dropdown-toggle", function () {
    var elem = $(this);
    elem.parent().toggleClass('open');
});

import './scss/bo.scss';
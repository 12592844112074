
$(document).ready(function () {
    setTimeout(function () {
        var tailleP = $('#divMiddleCnt').width();
        $('.bottomNav').width(tailleP);
    }, 700);
        $('.bottomNav').addClass('affix');

    $('.navbar-toggler.sidebar-toggler').click(function () {
        setTimeout(function () {
            var tailleP = $('#divMiddleCnt').width();
            $('.bottomNav').width(tailleP); }, 700);
    });
    if ($(window).width() > 1000) {
        $('.display-buttons.collapse').addClass("show");
    };
});

$(window).resize(function () {
    var tailleP = $('#divMiddleCnt').width();
    $('.bottomNav').addClass('affix');
    $('.bottomNav').width(tailleP);
});


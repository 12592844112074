
$(document).ready(function () {
    var tailleP = $('#divMiddleCnt').width();
    $('#divSessionTimeoutBoxCnt').width(tailleP);

    $(window).on('scroll', function (event) {
        var scrollValue = $(window).scrollTop();
        
        if (scrollValue >= 1) {
            $('.showmsgaffix').addClass('affixh');
        } else {
            $('.showmsgaffix').removeClass('affixh');
        }
    });

});
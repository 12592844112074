


$(document).ready(function () {
    AjustWidthElmnt();
    BtnMenu("#tooglerSidebar");
    BtnMenu("#tooglerSidebarLg");
});

$(window).on("orientationchange", function (event) {
    AjustWidthElmnt();
});

$(window).resize(function () {
    AjustWidthElmnt();
});



function BtnMenu(btn) {
    $(btn).click(function () {
        AjustWidthElmnt();
    });
};

function AjustWidthElmnt() {
    setTimeout(function () {
        var WidthDiv = $("#divMiddleCnt").width();
        
        $(".RecupTaille").width(WidthDiv - 40);
        
    }, 500);

};